exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-jsx": () => import("./../../../src/pages/articles.jsx" /* webpackChunkName: "component---src-pages-articles-jsx" */),
  "component---src-pages-events-jsx": () => import("./../../../src/pages/events.jsx" /* webpackChunkName: "component---src-pages-events-jsx" */),
  "component---src-pages-i-gatti-lo-sanno-mostra-jsx": () => import("./../../../src/pages/i_gatti_lo_sanno_mostra.jsx" /* webpackChunkName: "component---src-pages-i-gatti-lo-sanno-mostra-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-press-jsx": () => import("./../../../src/pages/press.jsx" /* webpackChunkName: "component---src-pages-press-jsx" */),
  "component---src-pages-seo-metadata-jsx": () => import("./../../../src/pages/seo_metadata.jsx" /* webpackChunkName: "component---src-pages-seo-metadata-jsx" */)
}

